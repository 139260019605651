import React, { useState } from "react";
import { checkUpdate } from '../../services/index/checkUpdate';

function DownloadAction() {
    function handleClick(e) {
        e.preventDefault();
        checkUpdate();
    }
    return (
        <a href="#" onClick={handleClick}>
            直接下载
        </a>
    );
}

function GetYear() {
    let year = 2024;
    let yearDataString = "";
    if (new Date().getFullYear() > year) {
        yearDataString = "- " + new Date().getFullYear();
    }
    return (<p>© Copyright {year}{yearDataString} Aftme.LLC </p>);
}

export function Index() {
    const [language, setLanguage] = useState('en'); // Default language is English

    const texts = {
        en: {
            home: "Home",
            about: "About",
            explore: "Explore More",
            contact: "Contact Us",
            companyDescription: "Focused on cutting-edge technologies in automation and artificial intelligence for fishing, planting, and aquaculture.",
            resources: "Explore and Develop",
            copyright: "All rights reserved.",
            email: "mail@aftme.com",
            language: "Language",
            footerDescription: "Aftme - Resource Exploration, Utilization, and Enhancement",
            aiAndGenetic: "Combining artificial intelligence, genetic editing, and automation technology for more efficient exploration and development of resources in the seabed, deserts, and polar regions.",
            project1: "Automated Ocean Intelligent Fishing",
            project2: "Gene-edited plants and automated cultivation",
            projectsTitle: "Projects"
        },
        zh: {
            home: "首页",
            about: "关于",
            explore: "了解更多",
            contact: "联系我们",
            companyDescription: "专注于自动化和人工智能开展前沿捕捞、种植、养殖业务技术。",
            resources: "资源探索和开发公司",
            copyright: "版权所有。",
            email: "mail@aftme.com",
            language: "语言",
            footerDescription: "Aftme-资源开发和利用和改进",
            aiAndGenetic: "结合人工智能和基因编辑技术和自动化技术，更有效探索和开发海底、沙漠、极地等领域的资源。",
            project1: "自动化海洋智能捕捞",
            project2: "基因编辑作物和自动化种植",
            projectsTitle: "项目"
        },
        ja: {
            home: "ホーム",
            about: "会社情報",
            explore: "さらに探る",
            contact: "お問い合わせ",
            companyDescription: "自動化と人工知能を活用した最先端の漁業、農業、養殖技術に焦点を当てています。",
            resources: "資源探索および開発会社",
            copyright: "著作権所有。",
            email: "mail@aftme.com",
            language: "言語",
            footerDescription: "Aftme - 資源探索、利用および強化",
            aiAndGenetic: "人工知能、遺伝子編集技術、及び自動化技術を組み合わせて、海底、砂漠、極地などの資源をより効率的に探索・開発します。",
            project1: "自動化海洋インテリジェント漁業",
            project2: "基因編集植物と自動化栽培",
            projectsTitle: "プロジェクト"
        }
    };

    const toggleLanguage = (lang) => {
        setLanguage(lang);
    };

    return <>
        <meta charSet="utf-8" />
        <link rel="stylesheet" href="/thirdparty/static/bootstrap.min.css" />
        <link rel="stylesheet" href="/thirdparty/static/w3.css" />
        <link rel="stylesheet" href="/thirdparty/static/style.css" />
        <link href="/thirdparty/static/main.css" rel="stylesheet" />
        <link
            href="/thirdparty/static/css2-family=Montserrat-wght@100;400;600;700&display=swap.css"
            rel="stylesheet"
        />

        <div
            id="myHeader"
            style={{ backgroundColor: "#1491c7", color: "#FEFEFE" }}
        >
            <header className="container">
                <img id="header-logo" src="/thirdparty/static/logo.svg" />
                <div className="nav desktop" id="nav1" style={{ display: "flex", alignItems: "center" }}>
                    <button>
                        <a href="#">{texts[language].home}</a>
                    </button>
                    <button style={{ marginLeft: "20px" }}>
                        <a href="#">{texts[language].about}</a>
                    </button>

                    {/* Language Toggle Button */}
                    <div style={{ marginLeft: "20px" }}>
                        <select
                            value={language}
                            onChange={(e) => toggleLanguage(e.target.value)}
                            style={{
                                padding: "5px",
                                backgroundColor: "#1491c7",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                            }}
                            onFocus={(e) => e.target.style.outline = 'none'}
                            onBlur={(e) => e.target.style.outline = 'none'}
                        >
                            <option value="en">English</option>
                            <option value="zh">中文</option>
                            <option value="ja">日本語</option>
                        </select>
                    </div>
                </div>
                <nav role="navigation" className="mobile">
                    <div id="menuToggle">
                        <input type="checkbox" />
                        <span />
                        <span />
                        <span />
                        <ul id="menu">
                            <a href="#">
                                <li>{texts[language].home}</li>
                            </a>
                            <a href="#">
                                <li>{texts[language].explore}</li>
                            </a>
                        </ul>
                    </div>
                </nav>
            </header>
        </div>

        <div id="header_placeholder" />
        <div id="section1" className="rellax" data-rellax-speed={-3} style={{ backgroundColor: "#1491c7", color: "#FEFEFE" }}>
            <div className="container">
                <div className="col-md-6">
                    <h2>Advanced Future Technologies</h2>
                    <h2>Multi-Dimension Exploration</h2>
                    <br />
                    <br />
                    <p>{texts[language].companyDescription}</p>
                    <p>{texts[language].aiAndGenetic}</p>
                </div>
                <div className="w3-content w3-section">
                    <div className="col-md-6">
                        <img
                            className="mySlides w3-animate-fading "
                            src="/thirdparty/static/Rocket.gif"
                            style={{ width: "100%" }}
                            id="fishing-img"
                        />
                    </div>
                </div>
            </div>
        </div>

        <section id="section2">
            <div className="rellax" data-rellax-speed={0}>
                <div className="container">
                    <div className="col-sm-6 col-md-6">
                        <h1>{texts[language].resources}</h1>
                        <p style={{ textAlign: "center" }}>{texts[language].companyDescription}</p>
                        <p style={{ textAlign: "center" }}>{texts[language].aiAndGenetic}</p>
                        <p />
                    </div>
                    <div className="col-sm-6 col-md-6 download">
                        <h1>{texts[language].explore}...</h1>
                        <div style={{ textAlign: "center" }}>
                            <button>
                                <a >{texts[language].explore}</a>
                            </button>
                            <button>
                                <a href={`mailto:${texts[language].email}`}>
                                    {texts[language].contact}
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="section3" style={{ padding: "40px 0", backgroundColor: "#f9f9f9" }}>
            <div className="container">
                <h2 style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    fontSize: "32px",
                    fontWeight: "600",
                    color: "#818181"
                }}>
                    {texts[language].projectsTitle}
                </h2>
                <div className="row" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                    {/* Project 1 */}
                    <div className="col-sm-6 col-md-3" style={{ marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img
                            src="/thirdparty/static/demo1.jpeg"
                            alt="Demo 1"
                            style={{
                                width: "100%",
                                maxWidth: "250px",
                                height: "200px",
                                objectFit: "cover",
                                borderRadius: "8px",
                                marginBottom: "10px"
                            }}
                        />
                        <p
                            style={{
                                textAlign: "center",
                                color: "#818181",
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "10px"
                            }}
                        >
                            {texts[language].project1}
                        </p>
                    </div>
                    {/* Project 2 */}
                    <div className="col-sm-6 col-md-3" style={{ marginBottom: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <img
                            src="/thirdparty/static/demo2.jpeg"
                            alt="Demo 2"
                            style={{
                                width: "100%",
                                maxWidth: "250px",
                                height: "200px",
                                objectFit: "cover",
                                borderRadius: "8px",
                                marginBottom: "10px"
                            }}
                        />
                        <p
                            style={{
                                textAlign: "center",
                                color: "#818181",
                                fontSize: "18px",
                                fontWeight: "500",
                                marginTop: "10px"
                            }}
                        >
                            {texts[language].project2}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        {/* Footer */}
        <div className="footer">
            <div className="container">
                <div className="col-sm-3">
                    <GetYear />
                    <br />
                    <p>{texts[language].copyright}</p>
                </div>
                <div className="col-sm-3"></div>
                <div className="col-sm-3"></div>
                <div className="col-sm-3">
                    <img src="/thirdparty/static/logo.svg" alt={texts[language].footerDescription} />
                    <p><a href={`mailto:${texts[language].email}`}>{texts[language].email}</a></p>
                </div>
            </div>
        </div>
    </>;
}
